import React, { useEffect, useState } from 'react'
import { convertToWei, isNumber, WeiToNumber } from 'utils/helpers';
import Dropdown from 'components/InputDropdown';
import { ethers } from "ethers";
import {
  BECCONTRACTADDRESS,
  USDTCONTRACTADDRESS,
  BUSDCONTRACTADDRESS,
  USDTABI,
  USDCABI,
  BUSDABI,
  USDCCONTRACTADDRESS
} from '_blockchain/contractsAbi'
import { useWalletContext } from "hooks/walletContext";
import { alertSuccess, alertError } from 'utils/alerts';
import { useAccount, useSigner, } from 'wagmi'

export default function DepositToken() {
  const [tokens, setTokens] = useState('')
  const [selectedCoin, setSelectedCoin] = useState()
  const [loading, setLoading] = useState(false)
  const [currentBalance, setCurrentBalance] = useState(0)
  const [decimalValForTokens, setDecimalValForTokens] = useState(18)

  const {
    getUSDTContractInstance,
    getConnectAddress,
    getBUSDContractInstance,
    getUSDCContractInstance,
    getContractInstance
  } = useWalletContext()
  const { address, isConnected } = useAccount();
  const { data: wagmiSigner } = useSigner();

  const onChangeAmount = (e) => {
    let tokens = e.target.value.toString()
    if (isNumber(tokens)) setTokens(tokens)
  }


  const contributeToContract = async (coinType, tokens) => {
    let signerAddress = await getConnectAddress()

    if (tokens < 50) return alertError({ msg: "Minimum 50 USD are required to contribute" })
    if (tokens > 100000) return alertError({ msg: "Maximum 100000 USD are allowed to contribute" })

    let CoinContractAddress
    let CoinContractABI

    if (coinType == 'USDC') {
      CoinContractAddress = USDCCONTRACTADDRESS
      CoinContractABI = USDCABI
    } else if (coinType == 'BUSD') {
      CoinContractAddress = BUSDCONTRACTADDRESS
      CoinContractABI = BUSDABI
    } else {
      CoinContractAddress = USDTCONTRACTADDRESS
      CoinContractABI = USDTABI
    }
    setLoading(true)
    try {

      let pb;

      if (coinType == 'USDC') {
        pb = await getUSDCContractInstance()
      } else if (coinType == 'BUSD') {
        pb = await getBUSDContractInstance();
      } else {
        pb = await getUSDTContractInstance()
      }

      const decimals = await pb?.decimals();
      const amountIn = ethers.utils.parseUnits(tokens.toString(), decimals)
      const allowance = await pb?.allowance(signerAddress, BECCONTRACTADDRESS);
      // console.log("allowance : ", allowance)
      if (allowance < amountIn) {
        const pba = await pb?.approve(BECCONTRACTADDRESS, ethers.utils.parseUnits(currentBalance.toString(), decimals));
        await pba.wait()
      }
      // console.log("amountIn >>>>>>>>", amountIn);
      const tb = await getContractInstance()
      const contri = await tb?.contribute(CoinContractAddress, amountIn, {
        gasLimit: 1000000
      });
      await contri.wait()

      alertSuccess({ msg: `You have contributed ${tokens} ${coinType} successfully` })
      getBalance("coinType")
      setTokens(0)
      setLoading(false)

      setTimeout(() => {
        window.location.reload()
      }, 400)

    } catch (error) {
      console.log("Contribute : error :: -------", error);
      let msg = error.message.split("error=")[1]?.split('"stack":')[0]?.split(',')[3] ?? "Something Went Wrong"
      alertError({ msg: msg })
      setLoading(false)
    }

  }


  const convertUSDTToBEC = (x) => {
    if (!x) {
      x = 1
    }
    x = x * (currentRate)
    return x.toLocaleString("en-US");
  };

  const getBalance = async (coinType) => {

    let signerAddress = await getConnectAddress()
    let contrInstance

    if (coinType == 'USDC')
      contrInstance = await getUSDCContractInstance()
    else if (coinType == 'BUSD')
      contrInstance = await getBUSDContractInstance()
    else
      contrInstance = await getUSDTContractInstance()


    try {
      let balance = await contrInstance.balanceOf(signerAddress)
      let decimalVal = await contrInstance.decimals();
      setDecimalValForTokens(decimalVal);
      setCurrentBalance(balance.toString())
    } catch (error) {
      setCurrentBalance(0)
    }
  }

  const setAvailableCoins = () => {
    setTokens(currentBalance)
  }


  useEffect(() => {
    if (isConnected && wagmiSigner) {
      getBalance(selectedCoin)
    }
  }, [selectedCoin, isConnected, wagmiSigner])

  const [currentRate, setCurrentRate] = useState(0)
  const getRates = async () => {
    const tb = await getContractInstance();
    const tokenRate = await tb.viewRate();
    const rate1 = parseFloat((+tokenRate.toString()) / 1000);
    setCurrentRate(rate1);
  }

  useEffect(() => {
    if (isConnected && wagmiSigner) {
      getRates()
    }
  }, [isConnected, wagmiSigner])


  return (
    <div className="mt-8 border border-website-480 rounded-lg w-full p-6">
      <div className="flex gap-2 cursor-pointer">
        <div className="text-base">
          Deposit {selectedCoin}<br></br>
          <span className="text-xs text-[#949494]">
            Minimum 50 tokens are required to contribute
          </span>
        </div>

      </div>
      <div className="pt-3 mt-6">
        <div className='text-xs mb-1'>Your balance is {WeiToNumber(currentBalance, decimalValForTokens)} {selectedCoin}</div>
        <div className="bg-website-500 px-2 py-2 items-center flex justify-between">
          <input
            autoFocus
            value={tokens}
            onChange={onChangeAmount}
            placeholder={`Enter ${selectedCoin} coin`}
            type={'tel'}
            className="w-[110px] sm:w-full bg-transparent border-none active:border-none text-sm font-normal p-0"
            style={{ boxShadow: 'none' }}
          />
          <Dropdown onchange={(item) => setSelectedCoin(item.id)} />
        </div>
      </div>
      <div className='flex flex-row justify-between'>
        <div className='text-sm mt-3'>{tokens || 1} {selectedCoin} =  <span className='text-primary-900'>{` ${convertUSDTToBEC(tokens)}`} BEC</span></div>
        <span onClick={() => {
          setTokens(WeiToNumber(currentBalance))
        }} className='mr-5 cursor-pointer hover:opacity-70'>Max</span>
      </div>
      <div className="pt-4">
        <button
          disabled={loading}
          onClick={() => contributeToContract(selectedCoin, tokens)}
          className="bg-primary-900 rounded-lg py-1 px-1 text-website-500 w-[10rem] flex flex-row justify-center items-center">
          <span className='mr-2'>Deposit</span> {loading && _renderLoading()}
        </button>
      </div>
    </div>
  )
}

const _renderLoading = () => {
  return (
    <svg
      className="animate-spin -ml-1 mr-3 h-4 w-4"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="3"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};