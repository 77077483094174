import React, { useState } from 'react'
import Buysell1 from "images/Buysell.png";
import Buysell5 from "images/Buysell.png";
import Buysell2 from "images/passes.png";
import Buysell3 from "images/metaverse.png";
import Buysell4 from "images/ownership.png";
import Buysell from 'images/cyberpunk-2077-sunny-day-5p.jpg';

const RoadMap = () => {
    const [timelineElements, setTimelineElements] = useState([
        {
            id: 1,
            active: true,
            imgName : Buysell1,
            title: "Phase 1"
        },
        {
            id: 2,
            imgName : Buysell2,
            title: "Phase 2"
        },
        {
            id: 3,
            imgName : Buysell3,
            title: "Phase 3"
        },
        {
            id: 4,
            imgName : Buysell4,
            title: "Phase 4"
        },
        {
            id: 5,
            imgName : Buysell5,
            title: "Step 5"
        },
    ])
    let workIconStyles = { background: "#06D6A0" };
    let schoolIconStyles = { background: "#f9c74f" };
    const [contentTitle, setContentTitle] = useState("Phase")
    const [contentId, setContentId] = useState("1")
    const [contentImg, setContentImg] = useState(Buysell1)

    const setActiveFunc = (itemId) => {
        // for(item in timelineElements){
        timelineElements.map((item) => {
            item["active"] = false
        })
        timelineElements[itemId - 1]["active"] = true
        setContentTitle(timelineElements[itemId - 1]["title"])
        setContentId(timelineElements[itemId - 1]["id"])
        setContentImg(timelineElements[itemId-1]["imgName"])
    }
    return (
        <div className='flex flex-col my-28 justify-center antialiased' >
            <div className="container text-center text-[2.235rem] font-medium mb-8" style={{ fontFamily: 'Strasua' }}>Roadmap</div>
            <div className='container flex  md:flex-row flex-col w-full rounded-md '>
                <div className='bg-website-220 rounded-md text-white md:p-9 lg:p-12 p-7 md:w-1/2 w-full h-full flex justify-content-center place-items-center'>
                    {/* <!-- component --> */}
                    <div className=" w-full  flex flex-col justify-center ">
                        <div className="  w-full ">

                            <div className="relative text-gray-700 antialiased text-sm font-semibold">

                                {/* <!-- Vertical bar running through middle --> */}
                                <div className="hidden sm:block w-0.5 bg-primary-900 absolute h-full left-1/2 transform -translate-x-1/2"></div>
                                {timelineElements.length && timelineElements.map((item) =>
                                    item?.id % 2 == 0 ?
                                        // {/* <!-- Right section, set by justify-end and sm:pl-8 --> */}
                                        <div className={`mt-6 sm:mt-0 sm:pt-12 ${item.id == timelineElements.length ? "sm:pb-12" : ''}`}>
                                            <div className="flex flex-col sm:flex-row items-center">
                                                <div className="flex justify-start w-full mx-auto items-center">
                                                    {/* <div className='font-semibold text-white'>Fabricate</div> */}
                                                    <div className="w-full sm:w-1/2 sm:pr-8">
                                                        <div className={`p-4 ${item.active ? 'text-primary-900 text-lg' : 'text-[#9D9D9D] text-sm'} flex sm:justify-end justify-center rounded shadow`}>
                                                            {item?.title}
                                                        </div>
                                                    </div>
                                                </div>
                                                {item?.active ?
                                                    <div onMouseOver={() => setActiveFunc(item?.id)} onClick={() => setActiveFunc(item?.id)} className="rounded-full cursor-pointer bg-[#222125] border-[#e5bf00] border-2 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                                        </svg>
                                                    </div>
                                                    :
                                                    <div onMouseOver={() => setActiveFunc(item?.id)} onClick={() => setActiveFunc(item?.id)} className="rounded-full cursor-pointer bg-[#9D9D9D] w-2 h-2 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">

                                                    </div>
                                                }

                                            </div>
                                        </div>
                                        :
                                        // {/* <!-- Right section, set by justify-end and sm:pl-8 --> */}
                                        <div className={`mt-6 sm:mt-0 sm:pt-12 ${item.id == timelineElements.length ? "sm:pb-12" : ''}`}>
                                            <div className="flex flex-col sm:flex-row items-center">
                                                <div className="flex justify-end w-full mx-auto items-center">
                                                    {/* <div className='font-semibold text-white '>BM Detailing </div> */}
                                                    <div className="w-full sm:w-1/2 sm:pl-8">
                                                        <div className={`p-4 ${item.active ? 'text-primary-900 text-lg' : 'text-[#9D9D9D] text-sm'} flex sm:justify-start justify-center rounded shadow`}>
                                                            {item?.title}
                                                        </div>
                                                    </div>
                                                </div>
                                                {item?.active ?
                                                    <div onMouseOver={() => setActiveFunc(item?.id)} onClick={() => setActiveFunc(item?.id)} className="rounded-full cursor-pointer bg-[#222125] border-[#e5bf00] border-2 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                                        </svg>
                                                    </div>
                                                    :
                                                    <div onMouseOver={() => setActiveFunc(item?.id)} onClick={() => setActiveFunc(item?.id)} className="rounded-full cursor-pointer bg-[#9D9D9D] w-2 h-2 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">

                                                    </div>
                                                }
                                            </div>
                                        </div>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
                <div className='bg-primary-900 rounded-md sm:p-9 lg:p-12 p-7 md:w-1/2 w-full'>
                    <div className='flex justify-center place-items-center bg-[#ccc] h-[300px]'>
                        {/* <img src={Buysell} className="rounded-lg" alt='Not Found' /> */}
                        </div>
                    <div className='mt-8 mb-6'>
                        <div>
                            <span className='text-black text-lg'>0{contentId} - {contentTitle}</span>
                        </div>
                        <div className='h-[1px] bg-black w-full my-5'></div>
                        <div className=' text-sm text-black'>Comming Soon</div>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default RoadMap