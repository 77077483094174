import React, { useState } from "react";

import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useDispatch } from "react-redux";
import { apiPOST } from "utils/apiHelper";
import userReducer, { userSlice } from "../../app/user/user";
import bgImage from "../../images/backgroundLogin.png";
import cover from "../../images/coverimagelogin.png"
import Checkbox from "shared/Checkbox/Checkbox";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonThird from "shared/Button/ButtonThird";

const PageLogin = ({ className = "" }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [loding, setLoding] = useState(false)
    const [error, setError] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState('')
    const [showPass, setShowPass] = useState(false);
    const [passworderror, setpasswordError] = useState("");
    const [emailerror, setemailError] = useState("");
    const [submitClicked, setSubmitClicked] = useState(false)

    async function loginClickHandler() {
        if (!email) {
            setemailError("Email is required");
            return
        }
        if (!password) {
            setpasswordError('Password is required ');
            return
        }
        let payload = {
            email,
            password,
        };
        // try{
        //   setLoading(true);
        //   let response = await apiPOST("/v1/auth/login", payload);
        //   setLoading(false);
        //   if (response.status == "200") {
        //     const { user, tokens } = response.data;
        //     localStorage.setItem("accessToken", tokens.access.token);
        //     dispatch(userSlice.actions.changeCurrentUser({ ...user, tokens }));
        //     clearError();
        //     history.replace("/"); 
        //   } else {
        //     // setError(response?.data?.message);
        //     // if (!email) setemailError("Email is required"); 
        //     setemailError(response?.data?.message);
        //   }
        // }
        // catch(error){
        //   setemailError(error);
        // }

    }

    const handleEmail = (e) => {
        setemailError("");
        setEmail(e.target.value);
    };

    const handlePassword = (e) => {
        setpasswordError("");
        setPassword(e.target.value);
    };
    /* Login */
    const clearError = () => {
        setError('')
    }

    async function loginClickHandler() {
        setSubmitClicked(true)
        if (!email) return 
        if (password.length<8) return 
        let payload = {
            email,
            password
        }
        history.replace('/dashboard')
        // window.location.push()
        // setLoding(true)
        // let response = await apiPOST('/v1/auth/login', payload)
        // setLoding(false)
        // if (response.status == '200') {
        //     const { user, tokens } = response.data
        //     dispatch(userSlice.actions.changeCurrentUser({ ...user, tokens }))
        //     clearError()
        //     history.replace('/')
        // } else {
        //     setError(response?.data?.message)
        // }
    }


    return (
        <div className={`nc-PageLogin nc-fill-container w-full bg-cover min-h-screen bg-no-repeat h-fit  ${className}`} data-nc-id="PageLogin"
            style={{ backgroundImage: `url(${cover})`, backgroundPosition: "right -25rem top -8rem" }}>
            <Helmet>
                <title>Login ||BEC Token | Elchai Ecosystem</title>
            </Helmet>


            <div className="w-full min-h-screen !h-full flex flex-col lg:flex-row   " >

                <div className="bg-[#161616] min-h-screen py-10 w-full px-2 !h-full lg:min-w-[30%] lg:max-w-[40%]  flex flex-col justify-center lg:justify-start items-center  lg:items-start ">
                    <div className="max-w-100% m-auto">


                        <h1 className="mt-10 mb-20 text-4xl font-strasua text-primary-900 uppercase">BEC Token</h1>
                        <h2 className="py-3 text-start  text-3xl leading-[115%] md:text-4xl font-normal text-neutral-900 dark:text-neutral-100 ">
                            {'Log in'}
                        </h2>
                        <p className="text-base mb-5" >{'Enter BEC Token login details'}</p>

                        <div className="max-w-md  space-y-6">

                            {/* FORM */}
                            <div className="grid grid-cols-1 gap-6" action="#" method="post">
                                <label className="block">
                                    <span className="text-xs p-2 text-neutral-800 bg-[#161616] dark:text-neutral-200 relative top-[0.75rem] left-3 ">
                                        Email Address <span className="text-red-600">*</span>
                                    </span>
                                    <Input
                                        onChange={(e) => setEmail(e.target.value)}
                                        type="email"
                                        /*    placeholder="example@example.com" */
                                        className="w-full md:w-[330px] mt-1 rounded dark:bg-[#161616] !bg-[#161616]"
                                        style={{ border: '1px solid #FFFFFF1C' }}
                                    />
                              {!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) && submitClicked ? <span className="block text-start pt-2  text-red-700">{"Invalid Email"}</span> : null}
                                </label>

                                <label className="block">

                                    <span className="text-xs relative top-[1rem] z-20 px-2 left-3 bg-[#161616]">
                                        Password  <span className="text-red-600">*</span>
                                    </span>
                                    <div className="relative">

                                        <Input
                                            // onChange={(e) => setPassword(e.target.value)}
                                            type={showPass ? "text" : "password"}
                                            onChange={handlePassword}
                                            style={{ border: '1px solid #FFFFFF1C' }}
                                            className="mt-1 w-full md:w-[330px] !bg-[#161616] rounded dark:bg-[#161616]" />
                                        <div className="flex cursor-pointer absolute mx-7 top-[11px]  right-[0]">
                                            {showPass ? (
                                                <div onClick={() => setShowPass(false)}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-5 w-5"
                                                        viewBox="0 0 20 20"
                                                        fill="#E2DBE6"
                                                    >
                                                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </div>
                                            ) : (
                                                <div onClick={() => setShowPass(true)} >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-5 w-5"
                                                        viewBox="0 0 20 20"
                                                        fill="#E2DBE6"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                                                            clipRule="evenodd"
                                                        />
                                                        <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                                                    </svg>
                                                </div>
                                            )}
                                            {/* hide password */}
                                        </div>
                                    </div>
                                    {submitClicked && password.length<8?  <span className="text-red-600 py-2">{"Enter Valid Password!"}</span> :null}

                                </label>
                                <div className="flex flex-col sm:flex-row justify-between items-center  text-neutral-800 dark:text-neutral-200 ">

                                    <Checkbox label="Remember me" labelClass="text-xs" className="text-xs " />
                                    <Link to="/forgot-pass" className="text-primary-900 py-2 text-xs text-primary-color">
                                        Forgot password?
                                    </Link>
                                </div>
                                <ButtonThird
                                    // disabled={!((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) && password.length>=8 )}
                                    loding={ loding}
                                    onClick={loginClickHandler}
                                    className={"w-full md:w-[330px] h-[36px] m-auto text-sm"}>Login</ButtonThird>
              <ButtonThird
                                    // disabled={!((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) && password.length>=8 )}
                                    loding={ loding}
                                    onClick={loginClickHandler}
                                    className={"w-full md:w-[330px] h-[36px] m-auto text-sm"}>Login</ButtonThird>

                                
                            </div>

                            {/* ==== */}
                            <span className="block text-center text-neutral-700 dark:text-neutral-300 text-sm">
                                Don't have an account? {` `}
                                <Link className="text-primary-900" to="/signup">
                                    Sign up
                                </Link>
                            </span>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    );

};

export default PageLogin;
