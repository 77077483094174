import React from 'react'
import DropDown from 'shared/NcDropDown/DropDown';
import { userSlice } from "../../app/user/user";
import { useDispatch } from "react-redux";
import {useHistory} from 'react-router-dom'
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { getTokenRate } from 'utils/getTokenRate';
import {useAccount, useDisconnect, useSigner} from 'wagmi'
import { useWalletContext } from 'hooks/walletContext';
import MenuBar from 'shared/MenuBar/MenuBar'
export default function DashboardHeader({
  title
}) {

  const [account, setAccount] = React.useState();
  const [error, setError] = React.useState("");
  const dispatch = useDispatch()
  const history = useHistory()
  const { data: wagmiSigner } = useSigner();
  const { connector: activeConnector, isConnected, address, isDisconnected, status } = useAccount()
  const { disconnect } = useDisconnect()

  const { getContractInstance, getConnectAddress } = useWalletContext()

  const disconnectWallet = async () => {
    disconnect()
    dispatch(userSlice.actions.removeUser({}))
    setTimeout(function() {history.replace("/");}, 700);
  };


  const [rate, setRate] = React.useState(0)

  const getRates = async () => {
    const tb = await getContractInstance()
    const tokenRate = await tb.viewRate();
    const rate = parseFloat(1 / tokenRate).toFixed(8)
    setRate(rate * 1000)
}

  const _getTokenRate = async () => {
    const tb = await getContractInstance();
    const tokenRate = await tb.viewRate();
    // const rate1 = parseFloat((+tokenRate.toString())/1000);
    const rate2 = parseFloat((1/+tokenRate.toString())).toFixed(6);
    setRate(rate2*1000);
    // setRate(rate1);
    // setRate(rate * 1000)
  }

  React.useEffect(() => {
    if(isConnected && wagmiSigner){
      _getTokenRate()
      getRates()
    }
  }, [isConnected, wagmiSigner])

  return (
    <div className='flex flex-col md:flex-row  justify-between md:items-center bg-website-220 px-0 lg:px-5 py-5 rounded-lg'>
      <div className='flex justify-between items-center'>
        <div className='flex items-center mb-2 md:mb-0'>
          <div className='block lg:hidden'><MenuBar isDashboard={true} /></div>
          <h1 className='text-lg'>{title}</h1>
        </div>
        <div className='mr-4 block md:hidden'>
          <ButtonPrimary onClick={() => disconnectWallet()}  fontSize="test-sm font-medium" sizeClass="px-6 py-1">Log Out</ButtonPrimary>
        </div>
      </div>
      
      <div className='divFlex flex flex-col md:flex-row md:items-center px-4 md:px-0'>
        <div className='mr-4 hidden md:block'>
          <ButtonPrimary onClick={() => disconnectWallet()}  fontSize="test-sm font-medium" sizeClass="px-6 py-1 w-[110px]">Log Out</ButtonPrimary>
        </div>
        <div className='text-sm'>
          1 BEC = <span className='text-primary-900'>{Number(Number(rate).toFixed(6))} USD</span></div>
          <DropDown className='text-neutral-300 w-auto md:w-[100px]' data={[{ name: "Logout" }]} account={address} disconnectWallet={disconnectWallet}/>
      </div>
    </div>
  )
}
