export const SEED_SALE = [
    {
        percentage: 10,
    },
    {
        percentage: 5,
    },
    {
        percentage: 5,
    },
    {
        percentage: 5,
    },

    {
        percentage: 5,
    },
    {
        percentage: 5,
    },
    {
        percentage: 5,
    },

    {
        percentage: 5,
    },
    {
        percentage: 5,
    },
    {
        percentage: 5,
    },
    {
        percentage: 5,
    },
    {
        percentage: 5,
    },
    {
        percentage: 5,
    },

    {
        percentage: 5,
    },
    {
        percentage: 5,
    },
    {
        percentage: 5,
    },
    {
        percentage: 5,
    },
    {
        percentage: 5,
    },
    {
        percentage: 5,
    },
]
export const PRIVATE_SALE_ROUND1 = [
    {
        percentage: 15,
    },
    {
        percentage: 10,
    },
    {
        percentage: 10,
    },
    {
        percentage: 10,
    },
    {
        percentage: 10,
    },
    {
        percentage: 10,
    },
    {
        percentage: 10,
    },
    {
        percentage: 10,
    },
    {
        percentage: 15,
    }
    
]
export const PRIVATE_SALE_ROUND2 = [
    {
        percentage: 20,
    },
    {
        percentage: 15,
    },
    {
        percentage: 15,
    },
    {
       percentage: 15,
    },
    {
       percentage: 15,
    },
    {
        percentage: 20,
    }
]
export const PRE_SALE = [
    {
        percentage: 25,
    },
    {
        percentage: 20,
    },
    {
        percentage: 20,
    },
    {
        percentage: 20,
    },
    {
        percentage: 15,
    },
]