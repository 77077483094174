// import { ethers } from "ethers";

/**
 * 
 * @param {String} str 
 * @returns Boolean 
 * Allowed signed and float numbers or empty string
 */
export function isNumber(str) {
    str = str.toString()
    const NUMBER_REGEX = /^-?\d*\.?\d*$/
    return NUMBER_REGEX.test(str)
}


export const WeiToNumberForTokenAddress = (t, decimal=18) => t / (10 ** decimal)
export const convertToWei = (t, decimal=18) => {
    // let tt =  ethers.BigNumber.from(t);
    // tt =tt.toString()
    // console.log("DECIMAL ",+tt,decimal,+tt * (10 ** decimal));
    return t * (10 ** decimal)
}
export const WeiToNumber = (t, decimal=18) => t / (10 ** decimal)