import { useNetwork,useSwitchNetwork } from 'wagmi'
 
function WagmiNetwork() {
  const { chain, chains:AvailChain } = useNetwork()
  const { chains, error, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork()
 
  return (
    <>
      {chain && <div>Connected to {chain.name}</div>}
      {AvailChain && (
        <div>Available chains: {AvailChain.map((chain) => chain.name)}</div>
      )}
      <div className='flex mr-4'>
      {chains.map((x) => (
        // <button
        //   disabled={!switchNetwork || x.id === chain?.id}
        //   onClick={() => switchNetwork?.(x.id)}
        // >

          
        //   {isLoading && pendingChainId === x.id && ' (switching)'}
        // </button>
         <button
         disabled={!switchNetwork || x.id === chain?.id}
         key={x.id}

         onClick={() => switchNetwork?.(x.id)}
         className="mr-4 bg-primary-900 rounded-lg py-1 px-1 text-website-500 w-[10rem] flex flex-row justify-center items-center">
         <span className='mr-2'>{x.name}</span> {isLoading && pendingChainId === x.id && ' (switching)' && _renderLoading()}
       </button>
      ))}
      </div>
    </>
  )
}

export default WagmiNetwork;

const _renderLoading = () => {
    return (
      <svg
        className="animate-spin -ml-1 mr-3 h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="3"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    );
  };