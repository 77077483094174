import React, {  useState } from "react";

const Counter = () => {
    const [days, setDays] = useState("");
    const [hours, setHours] = useState("");
    const [minutes, setMinutes] = useState("");
    const [seconds, setSeconds] = useState("");
    var deadline = new Date("May 01, 2023").getTime();
    var x = setInterval(function () {
        var now = new Date().getTime();
        var t = deadline - now;
        let dt = Math.floor(t / (1000 * 60 * 60 * 24));
        if (dt == 0) {
            dt = "00"
        } else if (dt < 10) {
            dt = "0" + dt;
        } else {
            dt = dt.toString()
        }
        setDays(dt);

        let hrs = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        if (hrs == 0) {
            hrs = "00"
        } else if (hrs < 10) {
            hrs = "0" + hrs;
        } else {
            hrs = hrs.toString()
        }

        setHours(hrs);

        let mins = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
        if (mins == 0) {
            mins = "00"
        } else if (mins < 10) {
            mins = "0" + mins;
        } else {
            mins = mins.toString()
        }
        setMinutes(mins);

        let sec = Math.floor((t % (1000 * 60)) / 1000);
        if (sec == 0) {
            sec = "00"
        } else if (sec < 10) {
            sec = "0" + sec;
        } else {
            sec = sec.toString()
        }
        setSeconds(sec);
        // document.getElementById("demo").innerHTML = days + "d "
        //     + hours + "h " + minutes + "m " + seconds + "s ";
        if (t < 0) {
            clearInterval(x);
            // document.getElementById("demo").innerHTML = "EXPIRED";
        }
    }, 1000);
    return (
        <div className="container bg-[#050505] relative mt-[89px] space-y-[150px] mb-[150px]" >
            <div className="flex md:gap-x-8 gap-x-2 justify-center">
                <div>
                    <div className="flex justify-center md:text-[3.43rem] sm:text-4xl text-2xl font-bold text-primary-900 py-2.5">{days || '-'}</div>
                    <div className="text-sm text-center mt-0.5 text-[#5e5e5e]">Days</div>
                </div>
                <div className="md:text-[2.75rem] sm:text-3xl text-lg pt-2.5 text-[#5e5e5e]">:</div>
                <div>
                    <div className="flex justify-center md:text-[3.43rem] sm:text-4xl text-2xl font-bold text-primary-900 py-2.5">{hours || '-'}</div>
                    <div className="text-sm text-center mt-0.5 text-[#5e5e5e]">Hours</div>
                </div>
                <div className="md:text-[2.75rem] sm:text-3xl text-lg pt-2.5 text-[#5e5e5e]">:</div>
                <div>
                    <div className="flex justify-center md:text-[3.43rem] sm:text-4xl text-2xl font-bold text-primary-900 py-2.5">{minutes || '-'}</div>
                    <div className="text-sm text-center mt-0.5 text-[#5e5e5e]">Minute</div>
                </div>
                <div className="md:text-[2.75rem] sm:text-3xl text-lg pt-2.5 text-[#5e5e5e]">:</div>
                <div>
                    <div className="flex justify-center md:text-[3.43rem] sm:text-4xl text-2xl font-bold text-primary-900 py-2.5">{seconds || '-'}</div>
                    <div className="text-sm text-center mt-0.5 text-[#5e5e5e]">Second</div>
                </div>
            </div>
        </div>
    );
}

export default Counter;
