import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import LightPaper from "images/Group 2975.png";
import PageConnectWallet from "containers/PageConnectWallet/PageConnectWallet";
import HowtoBuyToken from "./HowtoBuyToken";
import BecTableContainer from "components/becTableContainer/becTableContainer";
import VerticalSlider from "./VerticalSlider";
import Tokenomics from "components/tokonomics/tokonomics";
import RoadMap from "components/roadMap/roadmap";
import Counter from "components/counter/counter";

const HomePage = () => {
    const [days, setDays] = useState("");
    const [hours, setHours] = useState("");
    const [minutes, setMinutes] = useState("");
    const [seconds, setSeconds] = useState("");
    const { user } = useSelector((state => state))
    var deadline = new Date("May 01, 2023").getTime();
    var x = setInterval(function () {
        var now = new Date().getTime();
        var t = deadline - now;
        let dt = Math.floor(t / (1000 * 60 * 60 * 24));
        if (dt == 0) {
            dt = "00"
        } else if (dt < 10) {
            dt = "0" + dt;
        } else {
            dt = dt.toString()
        }
        setDays(dt);

        let hrs = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        if (hrs == 0) {
            hrs = "00"
        } else if (hrs < 10) {
            hrs = "0" + hrs;
        } else {
            hrs = hrs.toString()
        }

        setHours(hrs);

        let mins = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
        if (mins == 0) {
            mins = "00"
        } else if (mins < 10) {
            mins = "0" + mins;
        } else {
            mins = mins.toString()
        }
        setMinutes(mins);

        let sec = Math.floor((t % (1000 * 60)) / 1000);
        if (sec == 0) {
            sec = "00"
        } else if (sec < 10) {
            sec = "0" + sec;
        } else {
            sec = sec.toString()
        }
        setSeconds(sec);
        // document.getElementById("demo").innerHTML = days + "d "
        //     + hours + "h " + minutes + "m " + seconds + "s ";
        if (t < 0) {
            clearInterval(x);
            // document.getElementById("demo").innerHTML = "EXPIRED";
        }
    }, 1000);
    const [isShow, setIsShow] = useState(false)

    const toggleModal = () => {
        let t = !isShow;
        setIsShow(!isShow)
    }

    return (

        <div className="nc-PageHome relative overflow-hidden nc-fill-container">
            <Helmet>
                <title>BEC Token | Elchai Ecosystem</title>
            </Helmet>


            <BecTableContainer />
            <Counter />
            
            <div className="container relative mt-[89px] space-y-[150px] mb-[150px]" >
                <div  >
                    <div id="elchai-ecosystem" className="flex justify-center whitespace-nowrap text-[2rem] sm:text-[2.18rem] font-medium"><h1 style={{fontFamily:"Strasua"}}>Elchai Ecosystem</h1></div>
                    <div className="mt-[3rem]" >
                        <div className="flex flex-wrap justify-center" >
                            <div className="sm:flex pt-[27px] pb-[33px] xl:pr-[28px] gap-x-6 xl:border xl:border-website-480 xl:border-l-0 xl:border-t-0">
                                <div className="flex justify-center">
                                    <div className="min-w-[105px]  h-[105px] bg-[#161616] rounded-3xl bg-contain bg-center" style={{ background: 'url("/images/card (3).png")', backgroundRepeat: "no-repeat" }}></div>
                                </div>
                                <div className="max-w-[429px] mt-2 sm:mt-0">
                                    <div className="flex justify-center sm:flex sm:justify-start"><p className="text-2xl">BitElchai</p></div>
                                    <div><p className="text-[15px]">Car CLub</p></div>
                                    <div>
                                        <p className="text-[#BCBCBC] mt-3.5 text-base">
                                            Elchai’s vision is to drastically revolutionise the interpretation of the current economic system, linked to the world of crypto, luxury, real estate and investments, by offering the opportunity to take maximum advantage of both economic and usability to market operators and end users, through the use and adoption of each platform created by Elchai.
                                        </p>
                                    </div>
                                    {/* <div className="flex space-x-[6px] mt-7">
                                                <span className="text-primary-900 text-sm">Visit</span>
                                                <img src={VisitRightArrow} alt="RightArrow"/>
                                            </div> */}
                                </div>
                            </div>
                            <div className="sm:flex pt-[27px] pb-[33px] xl:pl-[28px] gap-x-6 xl:border xl:border-website-480 xl:border-r-0 xl:border-t-0 ">
                                <div className="flex justify-center">
                                    <div className="min-w-[105px] w-[105px] h-[105px] bg-[#161616] rounded-3xl bg-contain bg-center" style={{ backgroundImage: 'url("/images/card (1).png")' }}></div>
                                </div>
                                <div className="max-w-[429px] mt-2 sm:mt-0">
                                    <div className="flex justify-center sm:flex sm:justify-start"><p className="text-2xl">The Universe Eye</p></div>
                                    <div><p className="text-[15px]">Metaverse</p></div>
                                    <div>
                                        <p className="text-[#BCBCBC] mt-3.5 text-base">
                                            The Universe Eye is the first metaverse developed on hybrid technology that will enable the most
                                            important metaverses to date to be linked into one large common space, making them usable and
                                            connected.
                                            The peculiarity lies in the project's replication of the real world by truly replicating every detail in the
                                            digital world.
                                        </p>
                                    </div>
                                    {/* <div className="flex space-x-[6px] mt-7">
                                                <span className="text-primary-900 text-sm">Visit</span>
                                                <img src={VisitRightArrow} alt="RightArrow"/>
                                            </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-center" >
                            <div>
                                <div className="sm:flex pb-[27px] pt-[33px] xl:pr-[28px] gap-x-6 xl:border xl:border-website-480 xl:border-l-0 xl:border-b-0" >
                                    <div className="flex justify-center">
                                        <div className="min-w-[105px] w-[105px] h-[105px] bg-[#161616] rounded-3xl bg-contain bg-center" style={{ backgroundImage: 'url("/images/card (4).png")' }}></div>
                                    </div>
                                    <div className="max-w-[429px] mt-2 sm:mt-0">
                                        <div className="flex justify-center sm:flex sm:justify-start"><p className="text-2xl">NFT Marketplace</p></div>
                                        <div><p className="text-[15px]">Marketplace</p></div>
                                        <div>
                                            <p className="text-[#BCBCBC] mt-3.5 text-base">
                                                The idea of creating our own NFTs Marketplace was born to give anyone the opportunity, within our
                                                Metaverse, to experience visiting any city as if it were in reality, moving through the streets and visiting
                                                the buildings there and, most importantly, purchasing real assets directly in the Metaverse.

                                                How? through purchasing digital assets, i.e., NFTs present in our Marketplace, through the BEC Token.

                                            </p>
                                        </div>
                                        {/* <div className="flex space-x-[6px] mt-7">
                                                <span className="text-primary-900 text-sm">Visit</span>
                                                <img src={VisitRightArrow} alt="RightArrow"/>
                                            </div> */}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="sm:flex pb-[27px] pt-[33px] xl:pl-[28px] gap-x-6 xl:border xl:border-website-480 xl:border-r-0 xl:border-b-0" >
                                    <div className="flex justify-center">
                                        <div className="min-w-[105px] w-[105px] h-[105px] bg-[#161616] rounded-3xl bg-contain bg-center" style={{ backgroundImage: 'url("/images/card (2).png")' }}></div>
                                    </div>
                                    <div className="max-w-[429px] mt-2 sm:mt-0">
                                        <div className="flex justify-center sm:flex sm:justify-start"><p className="text-2xl">Elchainge</p></div>
                                        <div><p>Exchange</p></div>
                                        <div>
                                            <p className="text-[#BCBCBC] mt-3.5 text-base">
                                                <p>Elchai Group's fourth project refers to the creation of a proprietary Exchange that will enable:</p>
                                                <ul className="list-disc px-2 pl-5">
                                                    <li>Depositing legal tender currencies, cryptos and tokens into your personal account;</li>
                                                    <li>Buying, selling and trading the most important cryptos and tokens to date on the markets;</li>
                                                    <li>Buying crypto via FIAT;</li>
                                                    <li>Withdrawing directly into FIAT from bank accounts.</li>
                                                </ul>
                                            </p>
                                        </div>
                                        {/* <div className="flex space-x-[6px] mt-7">
                                                <span className="text-primary-900 text-sm">Visit</span>
                                                <img src={VisitRightArrow} alt="RightArrow"/>
                                            </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
           
            <div id="light-paper" className="my-[4rem] bg-black sm:my-[9.375rem] " >
                <div className="container relative  gap-12 sm:flex justify-center" style={{ backgroundImage: `url(${LightPaper})` }}>
                    <div className="flex flex-col justify-center">
                            <div className="flex justify-center">
                                <p className="text-[2.2rem] text-center" style={{ lineHeight: "50px",fontFamily:"Strasua" }}>Download Light Paper</p>
                            </div>
                            <div className=" sm:mt-3.5 flex justify-center">
                                <p className="text-sm sm:max-w-[350px] text-white opacity-50 text-center" style={{ lineHeight: "27px" }}>For know about us download light paper and grow with us.</p>
                            </div>
                            <div className=" mt-4 sm:mt-12  flex justify-center">
                                <button onClick={() => window.open("/ELCHAI_Light_paper_ENG.pdf", "_blank")} className="flex justify-center text-website-500 cursor-pointer py-2.5 rounded-3xl text-sm font-medium w-full bg-primary-900 h-11" >Download</button>
                            </div>
                    </div>
                </div>
            </div>
            <HowtoBuyToken />
           
            <Tokenomics />
            <RoadMap />
            {/* <VerticalSlider/> */}

        </div>
    );
}

export default HomePage;
