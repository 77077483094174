import React, { useEffect, useState } from 'react'
import { useWalletContext } from "hooks/walletContext";
import { useAccount, useSigner } from 'wagmi'

export default function TokenStageProgressDashboard() {

    const [round, setRound] = useState(0)
    const [rangeVal, setRangeVal] = useState(0)

    const { getContractInstance } = useWalletContext();
    const { isConnected } = useAccount();
    const { data: wagmiSigner } = useSigner();

    const getOngoingSale = async () => {
        try {

            const tb = await getContractInstance();
            let sale = await tb.saleOngoing();
            let saleNum =+(sale.toString())
            setRound(saleNum);
            if(saleNum == 1) setRangeVal(4)
            if(saleNum == 2) setRangeVal(35)
            if(saleNum == 3) setRangeVal(67)
            if(saleNum == 4) setRangeVal(100)
        } catch (error) {
            console.log("getOngoingSale :: error :", error);
        }
    }

    useEffect(() => {
        if (isConnected && wagmiSigner) {
            getOngoingSale()
        }
    }, [isConnected, wagmiSigner])

    return (
        <div className="w-full custom-range-slider">
            <div className="flex justify-between place-items-center mb-4">
                <Round title="Seed Sale" active={round == 1} />
                <Round title="Private Sale Round 1" active={round == 2} />
                <Round title="Private Sale Round 2" active={round == 3} />
                <Round title="Pre-Sale" active={round == 4} />
            </div>
            {/* <input disabled value={rangeVal} className="w-full px-2 h-2.5 bg-website-220 mt-5 rounded-2xl"  type="range" min="0" max="100"/> */}
            <div className='w-full bg-[#161616] h-2 rounded-lg'>
                <div style={{ width: rangeVal + "%" }} className="bg-primary-900 h-2 relative rounded-lg">
                    <div className='rounded-full w-4 h-4 absolute bg-primary-900 right-[-9px] top-[50%] translate-y-[-50%]'></div>
                    <div className='rounded-full w-5 h-5 absolute border border-primary-900 right-[-11px] top-[50%] translate-y-[-50%]'></div>
                </div>
            </div>

        </div>
    )
}

const Round = ({ title, active = false }) => {
    if (active) {
        return <div className="text-sm bg-website-500 p-3.5">
            <p className="max-w-[5.375rem] font-medium text-center text-primary-900">{title}</p>
        </div>
    }
    return <div className="text-sm">
        <p className="max-w-[5.375rem] font-medium text-center">{title}</p>
    </div>
}