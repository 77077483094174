import { menuSlice } from "app/menu/menu";
import DashboardHeader from "components/Header/DashboardHeader";
import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import imagepdf from '../../images/Group 2868.svg'

const PageKyc = () => {
  const dispatch = useDispatch()

  useEffect(() => {
      /* Set Active Menu */
      dispatch(menuSlice.actions.setSidebarActiveMenu('kyc-application'));
  }, [])

  return (
    <div className="nc-PageHome relative overflow-hidden nc-fill-container p-4">
            <Helmet>
                <title>BEC Token | Dashboard</title>
            </Helmet>
            <DashboardHeader title="KYC Verification" />

            <div className="m-auto mt-[12%] h-auto sm:h-[510px]" >
                        <h1 className="text-center text-3xl 2xl:text-4xl">KYC Verification</h1>
                        <h2 className="text-xs text-center mt-5 text-[#949494]">To comply with regulation each participant will have to go through indentity verification (KYC/AML)  </h2>
                        <h2 className="text-xs text-center mt-2 text-[#949494]"> to prevent fraud causes. Please, complete our fast and secure verification process </h2>
                        <h2 className="text-xs text-center mt-2 text-[#949494]"> participate in our token sale.</h2>
                        <div className="w-full lg:w-[774px] h-[400px] sm:h-[341px] flex flex-col items-center m-auto justify-between py-8 rounded-md bg-website-220 mt-6">
                                <div className="h-auto sm:h-[100px]"><img width={"110px"}  src={imagepdf} /></div>
                                <div className="-mt-[40px]">
                                     <h1 className="text-sm text-center mt-2 ">You have not submitted your necessary documents to verify your identity.</h1>
                                     <h1 className="text-sm text-center mt-2 ">In order to purchase our tokens, please verify your identity.</h1>
                                </div>
                                <div className="text-sm bg-primary-900 rounded-md"> <button className="text-[#050505] h-[36px] w-full sm:w-[280px] rounded-md text-center" style={{borderRadius:"6px"}}>Click here to complete your KYC</button></div>

                        </div>

            </div>
      
    </div>
  )
}

export default PageKyc
