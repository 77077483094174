import Input from "shared/Input/Input";
import React, { useState, useEffect } from "react";
import searchIcon from "images/icons/search.png";
import ethereumIcon from "images/icons/ethereumIcon.png";
import polygonIcon from "images/icons/polygonIcon.png";
import leftSideArrow from "images/icons/leftSide arrow.png";
import Button from "shared/Button/Button";
import USDT_ICON from 'images/icons/usdt.svg'
import DepositToken from "components/DepositToken";
import Tron from "./Tron";
import WagmiNetwork from "components/connectedNetwork";
const CryptoToken = () => {
  const [showEth, setShowEth] = useState(true)
  const [showEthDeposit, setShowEthDeposit] = useState(false)

  const [tron, setTron] = useState(false)
  const [eth, setEth] = useState(false)
  const [bgEth, setBgCrypto] = useState("bg-primary-900")
  const [bgTron, setBgFiat] = useState("bg-website-500")
  const [textEth, setTextCrypto] = useState("text-website-220")
  const [textTron, setTextFiat] = useState("text-white")

  const ShowFiat = () => {
    // return
    setBgFiat("bg-primary-900")
    setBgCrypto("bg-website-500")
    setTextFiat("text-website-220")
    setTextCrypto("text-white")
    setTron(true)
    setEth(false)
  }

  const ShowEth = () => {
    setBgCrypto("bg-primary-900")
    setBgFiat("bg-website-500")
    setTextCrypto("text-website-220")
    setTextFiat("text-white")
    setTron(false)
    setEth(true)
  }

  const [selectedCoin, setSelectedCoin] = useState({
    usdt: false
  })
  const ShowEthereum = () => {
    setShowEth(false)
    setShowEthDeposit(true)
  }
  const ShowEthereumDeposit = () => {
    setShowEth(true)
    setShowEthDeposit(false)
  }

  const showCoin = (coin) => {
    setSelectedCoin({ ...selectedCoin, [coin]: true })
    setShowEthDeposit(true)
  }

  useEffect(() => {
    setEth(true)
  }, [])

  return (
    <div className="pb-[10rem]">
      <div>
        {/* <div className="text-sm">Step 2</div> */}
        <div className="text-sm">Buy Tokens</div>
        <div className="pt-5 space-y-2">
          <div className="text-base">
            Choose currency and calculate BEC tokens price
          </div>
          <div className="text-xs text-[#949494]">
            You can buy our BEC tokens using USDT, USDC,  BUSD to become
            part of Our project.
          </div>
        </div>
      </div>
      {/* <div>
      <div className=" flex flex-col md:flex md:flex-row   gap-5 pt-4">
              <div onClick={ShowEth} className={`${bgEth} ${textEth} w-[19.313rem] py-2 rounded-lg text-center text-base cursor-pointer`}>
                ETH
              </div>
              <div onClick={ShowFiat} className={`${bgTron} ${textTron} w-[19.313rem] py-2 rounded-lg text-center text-base cursor-pointer`}>
                Tron
              </div>
            </div>
      </div> */}
      <div className="bg-website-220 rounded-lg mt-4">
        {/* <CryptoToken/> */}
        {/* <DepositEthereum /> */}
        {/* <FiatToken/> */}
        {
          eth ? <DepositToken /> : ""
        }
        {/* {
              tron ? <Tron/>:""
            } */}
      </div>
      <div>
        {/* <WagmiNetwork /> */}
      </div>
    </div>
  );
};

export default CryptoToken;
