import { useState, useEffect } from "react";
import './inputDropdown.scss'
import USDT_ICON from 'images/icons/usdt.svg'
import BUSD_ICON from 'images/icons/busd-icon.svg'
import USDC_ICON from 'images/icons/usdc-coin-icon.svg'
import dd_arrow_icon from 'images/icons/dd-arrow.png'
const data = [
  { id: "USDT", label: "USDT", icon: USDT_ICON },
  { id: "USDC", label: "USDC", icon: USDC_ICON },
  { id: "BUSD", label: "BUSD", icon: BUSD_ICON }
]

export default function Dropdown({ onchange = () => { } }) {
  const [isOpen, setOpen] = useState(false);
  const [items, setItem] = useState(data);
  const [selectedItem, setSelectedItem] = useState(data[0]);

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (obj) => {
    setSelectedItem(obj)
    toggleDropdown()
  }

  useEffect(() => {
    onchange(selectedItem)
  }, [selectedItem.id])


  return (
    <div className="custom-input-dropdown relative bg-[#3d3d3d] sm:px-4 sm:py-2 rounded-lg mt-2 sm:mt-0">
      <div className='dropdown p-1'>
        <div className='dropdown-header sm:pb-1' onClick={toggleDropdown}>
          <div className="flex sm:flex sm:flex-row sm:gap-4   items-center">
            <div className="flex place-items-center">
            <img className="h-[14px] w-[14px]  sm:h-[18px] sm:w-[18px] mr-1" src={selectedItem.icon} />
            <span className='text-[11px] sm:text-base'>{selectedItem.label || "Select Coin"}</span>
            
            </div>
            <div className="flex justify-center">
            <img src={dd_arrow_icon} className="ml-2 w-3 h-3 sm:w-4 sm:h-4" />

            </div>
          </div>
        </div>
        <div className={`dropdown-body bg-website-220 ${isOpen && 'open'} absolute w-full left-0`} style={{ marginTop: 2, border: "1px solid #3d3d3d !important" }}>
          {items.map(item => {
            if (item.id == selectedItem.id) return
            return (
              <div key={item.id} onClick={() => handleItemClick(item)} className="flex items-center hover:bg-website-500 cursor-pointer border-t border-[#5a5a5a] px-4 py-3">
                <img className="h-[18px] w-[18px] mr-1" src={item.icon} />
                <span className='text-base'>{item.label}</span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}