import React, { Fragment, useState } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { NAVIGATION_DEMO_2 } from "data/navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SocialsList from "shared/SocialsList/SocialsList";
import { ChevronDownIcon } from "@heroicons/react/solid";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { userSlice } from "app/user/user";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import ConnectWalletModal from "components/ConnectWalletModal/ConnectWalletModal";


const NavMobile = ({
  data = NAVIGATION_DEMO_2,
  onClickClose,
}) => {
  const {user} = useSelector(state=>state)
  const history = useHistory()
  const dispatch = useDispatch()

  const [isShow, setIsShow] = useState(false)
  const { connector: activeConnector, isConnected, address, isDisconnected, status } = useAccount()


  const toggleModal = () => {
    console.log("here")
    let t = !isShow;
    setIsShow(!isShow)
  }


  const _renderMenuChild = (item) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            <NavLink
              exact
              strict
              to={{
                pathname: i.href || undefined,
              }}
              className="flex px-4 py-2.5 text-neutral-900 dark:text-neutral-200 text-sm font-medium rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-[2px]"
              activeClassName="text-secondary"
            >
              <span
                className={!i.children ? "block w-full" : ""}
                onClick={onClickClose}
              >
                {i.name}
              </span>
              {i.children && (
                <span
                  className="block flex-grow"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="flex justify-end flex-grow"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-neutral-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.children && (
              <Disclosure.Panel>{_renderMenuChild(i)}</Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item, index) => {
    if(item.name == "Home" || item.name == "Elchai Ecosystem" || item.name=="Token" || item.name == "Light Paper" || item.name =="Our Team" || "" ){

      return  (
      <a  
      onClick={onClickClose}       
        className="flex text-white w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
      href={`${item.href}`}>{item.name} {item.type && (
        <ChevronDownIcon
          className="ml-1 -mr-1 h-4 w-4 text-neutral-400"
          aria-hidden="true"
        />
      )}</a> )
    }
    // return (
    //   <Disclosure
    //     key={item.id}
    //     as="li"
    //     className="text-neutral-900 dark:text-white"
    //   >
    //     <NavLink
    //       exact
    //       strict
    //       className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
    //       to={{
    //         pathname: item.href || undefined,
    //       }}
    //       activeClassName="text-secondary"
    //     >
    //       <span
    //         className={!item.children ? "block w-full" : ""}
    //         onClick={onClickClose}
    //       >
    //         {item.name}
    //       </span>
    //       {item.children && (
    //         <span
    //           className="block flex-grow"
    //           onClick={(e) => e.preventDefault()}
    //         >
    //           <Disclosure.Button
    //             as="span"
    //             className="flex justify-end flex-grow"
    //           >
    //             <ChevronDownIcon
    //               className="ml-2 h-4 w-4 text-neutral-500"
    //               aria-hidden="true"
    //             />
    //           </Disclosure.Button>
    //         </span>
    //       )}
    //     </NavLink>
    //     {item.children && (
    //       <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
    //     )}
    //   </Disclosure>
    // );
  };



  const logout = () => {
    dispatch(userSlice.actions.removeUser({}))
    history.replace('/')
  }

  return (
    <div className="relative overflow-y-auto w-full max-w-sm h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="py-6 px-5">
        <Logo />
        <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">

        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>
      <ul className="flex flex-col py-6 px-2 space-y-1">
        {data.map(_renderItem)}
      </ul>
      <div className="flex items-center justify-between px-5 space-x-2">
      {
        isConnected
        ? <div className="flex flex-col w-full gap-2">
          <Link to={"/dashboard"}>
          <div className="text-white">
            <ButtonPrimary className="w-full">Dashboard</ButtonPrimary>
          </div>
          </Link>
          <div className="">
            {/* <ButtonSecondary onClick={logout} className="flex-1 w-full">
              Logout
            </ButtonSecondary> */}
          </div>
          </div>
        : <ConnectWalletModal onClick={() => toggleModal()} mobile={true} />
        
        // <ButtonPrimary onClick={() => toggleModal()}  fontSize="test-sm font-medium" sizeClass="px-20 py-2">Login</ButtonPrimary>
        
        // [<ButtonPrimary href={"/login"} className="!px-10">
        //   Login
        // </ButtonPrimary>,
        // <ButtonSecondary href={"/Signup"} className="flex-1">
        //   Signup
        // </ButtonSecondary>]
      }
      </div>
    </div>
  );
};

export default NavMobile;
