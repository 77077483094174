import React, { useState } from 'react';
import leftArrow from 'images/icons/left-arrow.png'
import rightArrow from 'images/icons/right-arrow.png'
import sampleImg from 'images/hero-right-2.png'
import connectWallet from 'images/connectwalletsl.png'
import approveContri from "images/approvecontribution.png"; 
import depositToken from "images/depositToken.png"; 
import approveToken from "images/approveContri.png"; 
import totBal from "images/totalbaldash.png"; 
import loginContri from "images/loginContri.png"; 

const howToCards = [
    {
        number: "01",
        title: "Login",
        description: "Click on Login to deposit",
        image:loginContri
    },
    {
        number: "02",
        title: "Connect Wallet",
        description: "Connect with EVM compatible wallet",
        image:connectWallet
    }, {
        number: "03",
        title: "Deposit ",
        description: "Deposit Stable Coin to recieve the equivalent BEC token",
        image:depositToken
    }, {
        number: "04",
        title: "Permission",
        description: "Allow Stable coin to Transfer the Coin to Contract",
        image:approveToken 
    },
    {
        number: "05",
        title: "Approve",
        description: "Approve the Transaction on Contract to contribute",
        image:approveContri
    },
    {
        number: "06",
        title: "Total Balance",
        description: "View balance on dashboard after Successfull Transaction",
        image:totBal
    },
]
const HowtoBuyToken = () => {
    const [cards, setCards] = useState(howToCards.slice(0, 6))
    const [index, setIndex] = useState(0)

    const moveForward = () => {
        if (index < 4) {
            // setCards(howToCards.slice(index + 1, (index + 1) + 4))
            setIndex((i) => i + 1)
        }
    }

    const moveBackward = () => {
        if (index > 0) {
            // setCards(howToCards.slice(index - 1, (index - 1) + 4))
            setIndex((i) => i - 1)
        }
    }

    

// index<2?index:1, could help
    return (

        <div className='container md:my-6'>
            <div className='text-center mb-2'> <h1 className='uppercase font-bold text-4xl' style={{fontFamily:'Strasua'}}>How to buy bec Token</h1></div>
            <div className='flex justify-between w-full px-5'>
                <div className='cursor-pointer text-2xl' onClick={() => { moveBackward() }}>
                    <img className='w-10 h-10 ' src={leftArrow} />
                </div>
                <div className='cursor-pointer text-2xl' onClick={() => { moveForward() }}>
                <img className='w-10 h-10 ' src={rightArrow} />
                </div>
            </div>
            {/* md:${howToCards.slice(0,3)} */}
            <div className={`xl:grid gap-x-6 gap-y-11 hidden  xl:grid-cols-4  px-5 my-[10px] items-center justify-center `}>
                {cards.slice(0+index, 4+index).map((card, i) => {
                    return (
                        <CardDesign i={i} title={card.title} description={card.description} number={card.number} image={card.image}/>
                    )
                })}


            </div>
            <div className={`lg:grid hidden xl:hidden gap-x-6 gap-y-11  lg:grid-cols-3  px-5 my-[10px] items-center justify-center `}>
                {cards.slice(0+index, 3+index).map((card, i) => {
                    return (
                        <CardDesign i={i} title={card.title} description={card.description} number={card.number} image={card.image}/>
                    )
                })}


            </div>
            <div className={`sm:grid hidden lg:hidden gap-x-6 gap-y-11  grid-cols-2 px-5 my-[10px] items-center justify-center `}>
                {cards.slice(0+index, 2+index).map((card, i) => {
                    return (
                        <CardDesign i={i} title={card.title} description={card.description} number={card.number} image={card.image}/>
                    )
                })}


            </div>
            <div className={`flex sm:hidden gap-x-6 gap-y-11   px-5 my-[10px] items-center justify-center `}>
                {cards.slice(0+index, 1+index).map((card, i) => {
                    return (
                        <CardDesign i={i} title={card.title} description={card.description} number={card.number} image={card.image}/>
                    )
                })}


            </div>
        </div>

    )
}

export default HowtoBuyToken
const CardDesign = ({i,title,description,number,image}) => {
    return(
        <div key={i} className={`max-h-[450px] overflow-hidden bg-website-220 rounded-xl max-w-[558px] hide-scroll`}>
            <div className="flex gap-4 flex-col rounded h-72 justify-between  bg-[#2c2a2d]">
                 <img className='object-contain w-full h-[100%]' src={image} />
            </div>
            <div className="flex flex-row mt-7 mb-1 px-2 gap-3">
                <div>
                    <span className='text-primary-900 opacity-60 text-[60px] leading-[60px]'>{number}</span>
                </div>
                <div className='flex flex-col '>
                      <h1 className="whitespace-nowrap w-full mb-2 text-primary-900 text-[20px]">{title}</h1>
                    <p className="text-sm text-[#5e5e5e]" >{description}</p>
                </div>
            </div>
        </div>
    )
}