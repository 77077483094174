import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/icons/Asset 2-8.png"

const Footer = () => {
  return (
    <div className=" text-center bg-[#161616] h-auto my-auto " >
           <div className="flex sm:flex-row flex-col justify-center sm:gap-5 gap-7 w-full sm:w-3/4 m-auto  text-center  py-6"  >


<div className="pt-5 w-full sm:w-2/5 2xl:w-[40%] sm:flex sm:flex-row items-center my-auto " >
  <div className="flex justify-center sm:w-[110px] h-[110px] mb-3"> <img src={logo} /></div>
  <div className="flex flex-col sm:w-[80%] ">
          <div className="text-[#E5BF00] text-4xl xl:text-5xl font-strasua mb-3 flex justify-center">BEC TOKEN</div>
          <div className="text-[16px] flex justify-center ">Elchai Group The Ecosystem</div>
  </div>

 
</div>
<div className="main border  md:h-[270px] sm:hidden md:block"
style={{
 
  opacity: 0.4,
  borderImage:"linear-gradient(to bottom,rgb(16, 16, 16),rgb(240, 236, 236),rgb(16, 16, 16))",
  borderImageSlice:1
}}
></div>
{/* <div className="border  bg-black m-3"></div> */}
<div className="sm:mt-3 flex flex-col   sm:text-start sm:pl-[2rem] " >
  <div className="text-[16px] font-medium ">Address</div>
  <div className=" md:mt-[2.75rem] sm:mt-5">
    <div className="space-y-2 ">
      <div className="cursor-pointer text-[14px] text-[#FFFFFF] font-light">Elchai Group</div>
      <div className="cursor-pointer text-[14px] text-[#FFFFFF] font-light">Dubai, UAE </div>
      <div className="cursor-pointer text-[14px] text-[#FFFFFF] font-light">Abu Dhabi, UAE </div>
      <div className="cursor-pointer text-[14px] text-[#FFFFFF] font-light" >info@elchaigroup.com</div>
    
    </div>
  </div>
</div>
<div className="sm:mt-3 flex flex-col   sm:text-start sm:pl-[2rem]" >
  <div className="text-[16px] font-medium">Links</div>
  <div className="md:mt-[2.75rem] sm:mt-5 ">
    <div className="space-y-2">
      <div className="cursor-pointer text-[14px] text-[#FFFFFF] font-light"><a href="#">Home</a>  </div>
      <div className="cursor-pointer text-[14px] text-[#FFFFFF] font-light" ><a href="#">Elchai Ecosystem</a> </div>
      <div className="cursor-pointer text-[14px] text-[#FFFFFF] font-light"><a href="#">Token</a> </div>
      <div className="cursor-pointer text-[14px] text-[#FFFFFF] font-light"><a href="#">Light Paper</a></div>
      {/* <div className="cursor-pointer text-[14px] text-[#FFFFFF] font-light"><a href="#">Our Team</a></div> */}
    </div>
  </div>
</div>
</div>

    </div>
   
   /*  <div className="nc-Footer relative py-5  border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-1">
        <div className="col-span-1 text-center">
          Copyrights @BEC 2022. All rights reserved.
        </div>
      </div>
    </div> */
  );
};
export default Footer;
