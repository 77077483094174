import React, { Fragment, useState, useEffect } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import NotifyDropdown from "./NotifyDropdown";
import AvatarDropdown from "./AvatarDropdown";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Navigation from "shared/Navigation/Navigation";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react';
import ConnectWalletModal from "components/ConnectWalletModal/ConnectWalletModal";
import { alertError, alertInfo } from "utils/alerts";
import { useAccount, useConnect } from 'wagmi'
import ButtonSecondary from "shared/Button/ButtonSecondary";



const MainNav2Logged = () => {
  const { user } = useSelector(state => state)
  const dispatch = useDispatch()
  const history = useHistory()
  const [account, setAccount] = useState();
  const [error, setError] = useState("");
  const [isShow, setIsShow] = useState(false)


  const { connector: activeConnector, isConnected, address, isDisconnected, status } = useAccount()

  const toggleModal = () => {
    console.log("here")
    let t = !isShow;
    setIsShow(!isShow)
  }

  return (
    <div className={`nc-MainNav2Logged bg-[#050505] relative z-10 ${"onTop "}`}>
      <div className="container  py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <ConnectWalletToggel isShow={isShow} toggleModal={toggleModal} />
        <div className="flex justify-start flex-grow items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-2">
            <Navigation />
            {/* <div className="hidden sm:block h-6 border-l border-neutral-300 dark:border-neutral-6000"></div> */}
            {/* <div className="flex">
              <NotifyDropdown />
            </div> */}
            <div></div>
            {/* {
              user.id
              ? <AvatarDropdown /> 
              : <ButtonPrimary href={"/login"} fontSize="test-sm font-medium" sizeClass="px-11 py-2">Login</ButtonPrimary>
            } */}
            {
              isConnected ?
                <Link to={"/dashboard"}>
                  <ButtonSecondary fontSize="test-sm font-medium" sizeClass="px-11 py-2">Dashboard</ButtonSecondary>
                </Link>
                : <ButtonSecondary onClick={() => toggleModal()} fontSize="test-sm font-medium" sizeClass="px-11 py-2">Login</ButtonSecondary>
            }
          </div>
          <div className="flex items-center space-x-3 xl:hidden">
            {/* <NotifyDropdown /> */}
            {
              isConnected
                ? <AvatarDropdown />
                : null
            }
            {/* <AvatarDropdown /> */}
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export const truncateAddress = (address, connectWallet) => {
  if (!address) {

    return (

      <ButtonPrimary onClick={() => connectWallet()} fontSize="test-sm font-medium" sizeClass="px-11 py-2">Connect Wallet</ButtonPrimary>
    )
  }
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

const ConnectWalletToggel = ({ isShow = false, toggleModal }) => {
  function closeModal() {
    toggleModal()
  }

  return (
    <>
      <Transition appear show={isShow} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl flex justify-center text-left align-middle shadow-xl transition-all">
                  <ConnectWalletModal onClick={() => closeModal()} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default MainNav2Logged;
