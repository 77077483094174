import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from "react";
import ConnectWalletModal from "components/ConnectWalletModal/ConnectWalletModal";
import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";
import { userSlice } from "../../app/user/user";
import { Link, useHistory } from "react-router-dom";

const PageConnectWallet = ({ isShow = false, toggleModal }) => {
  const {user} = useSelector(state=>state)
  const dispatch = useDispatch()
  const history = useHistory()
  const [error, setError] = useState("");
  function closeModal() {
    toggleModal()
  }

  const connectWallet = async () => {
    let userObj = {
      "_id": "637e1a943fc4bc1e8019a9cd",
      "userId": "637c6dba6994e83444370324",
      "orgId": "637b6ca71027d500dca99f40",
      "active": true,
      "role": 1,
      "invitedBy": "6371e958118c693848594082",
      "createdAt": "2022-11-23T13:05:24.274Z",
      "updatedAt": "2022-11-23T13:05:24.274Z",
      "seqId": 56,
      "__v": 0,
      "userObj": {
        "_id": "637c6dba6994e83444370324",
        "fName": "Arpan",
        "lName": "Patra",
        "role": "user",
        "country": "",
        "phoneNumber": "",
        "profilePic": "https://stardust-asset-qa.s3.ap-southeast-1.amazonaws.com/uploads/1671016122596Image-16.png",
        "coverImage": "https://wallpaperaccess.com/full/1348033.jpg",
        "bio": "",
        "isEmailVerified": false,
        "kycStatus": false,
        "wallet": {
          "default": "0x00d53c0c962Ddfe4940FfC750Bf9d4dc8488C9B9",
          "metamask": "0x00d53c0c962Ddfe4940FfC750Bf9d4dc8488C9B9"
        },
        "active": true,
        "orgId": null,
        "brandId": null,
        "profileStatus": "activated",
        "gender": 0,
        "isPrivate": false,
        "status": "pending",
        "isAgent": false,
        "isFiat": false,
        "facebookUrl": "",
        "twitterUrl": "",
        "instagramUrl": "",
        "discordId": "",
        "username": "arpan",
        "email": "apatra@sdlccorp.com",
        "password": "$2a$08$gg9YCeVmUZQ63JQe3j2pjOAsiT0SUXWvJGvO.bCXjV7c9KWm4V70y",
        "createdAt": "2022-11-22T06:35:38.575Z",
        "updatedAt": "2022-12-14T11:08:47.230Z",
        "seqId": 397,
        "__v": 0,
        "referalCode": "arpan"
      },
    }
    try {
      // if (aaaaaaa) {
      //   dispatch(userSlice.actions.changeCurrentUser({ userObj, id: accounts }));
      //   history.replace("/dashboard")
      // }
    } catch (error) {
      setError(error);
    }
  };

  return (
    <>
      <Transition appear show={isShow} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl flex justify-center text-left align-middle shadow-xl transition-all">
                  <ConnectWalletModal onClick={() => closeModal()} connectWallet={connectWallet} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}


export default PageConnectWallet;