import React, { useEffect, useState } from "react";
import { menuSlice } from "app/menu/menu";
import DashboardHeader from "components/Header/DashboardHeader";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { ethers } from "ethers";

import { BECCONTRACTADDRESS, BECCONTABI } from "_blockchain/contractsAbi";
import moment from "moment";
import { SEED_SALE, PRIVATE_SALE_ROUND1, PRIVATE_SALE_ROUND2, PRE_SALE } from "./stages";
import { useWalletContext } from "hooks/walletContext";
import { WeiToNumber } from "utils/helpers";
import { alertError, alertSuccess } from "utils/alerts";
import { useAccount, useSigner } from "wagmi";


const PageMyToken = () => {
    const dispatch = useDispatch()
    const [canClaim, setCanClaim] = useState(false)
    const [tgeDate, setTgeDate] = useState()

    const { address, connector, isConnected } = useAccount();
    const { data: wagmiSigner } = useSigner();
    const { getContractInstance, getConnectAddress } = useWalletContext()

    const [vestedAmt, setvestedAmt] = useState(0);
    const [claimLoading, setClaimLoading] = useState(false);
    const [remainingAmt, setremainingAmt] = useState(0);
    const [claimAmt, setclaimAmt] = useState(0)
    const totalTokenUnvestedNow = async (second) => {
        const tb = await getContractInstance();
        const addr = await getConnectAddress();
        const claimAmt = await tb.showClaimedAmount(addr);
        const camt = WeiToNumber(claimAmt.toString())
        setclaimAmt(camt)
        // setvestedAmt(vested)
    }



    const [myBal, setmyBalance] = useState(0)
    const getMyBalance = async () => {
        let values = [];
        const tb = await getContractInstance();
        const tp = await tb.tokensPurchased(1, address);
        console.log("tokenPurchased :: 1", tp.toString());
        values.push(tp.toString())

        const tp2 = await tb.tokensPurchased(2, address);
        console.log("tokenPurchased ::2", tp2.toString());
        values.push(tp2.toString())

        const tp3 = await tb.tokensPurchased(3, address);
        console.log("tokenPurchased ::3", tp3.toString());
        values.push(tp3.toString())

        const tp4 = await tb.tokensPurchased(4, address);
        console.log("tokenPurchased ::4", tp4.toString());
        values.push(tp4.toString())
        const bal = values.reduce(
            (accumulator, currentValue) => accumulator + Number(currentValue),
            0
        );
        setmyBalance(WeiToNumber(bal.toString()))
        console.log("values", bal.toString());
    }

    const [isloading, setisloading] = useState(false)
    const claim = async () => {
        setisloading(true);
        setClaimLoading(true);
        try {
            const tb = await getContractInstance();
            const cliam = await tb.claim();
            await cliam.wait();
            alertSuccess({ msg: "Claimed Successfully." })
            setTimeout(() => {
                window.location.reload()
            }, 300)
        } catch (error) {
            console.log("error ::", error.message);
            // alert("Error While Claiming Amount")

            let msg = error.message.split("error=")[1]?.split('"stack":')[0]?.split(',')[3] ?? "Something Went Wrong"
            alertError({ msg: msg })
        } finally {
            setisloading(false);
            setClaimLoading(false);
        }
    }

    const [unReleasedTokens, setunReleasedTokens] = useState(0)
    const getMyUnreleasedTokens = async (second) => {
        try {
            const tb = await getContractInstance();
            const addr = await getConnectAddress();
            const uramt = await tb.showUnreleasedTotalAmount(addr);
            const ut = WeiToNumber(uramt.toString())
            setunReleasedTokens(ut)
        } catch (error) {

        }
    }


    const getWhenIsTheTGE = async () => {
        try {
            const contractInstance = await getContractInstance();
            const connectAddress = await getConnectAddress()
            let dateInUnix = await contractInstance.whenIsTheTge();
            if (dateInUnix <= 0) dateInUnix = 1682895600;
            let toDateNumber = dateInUnix * 1000
            setTgeDate(new Date(toDateNumber))
        } catch (error) {

        }
    }

    const checkCanClaimNow = async () => {
        try {
            const tb = await getContractInstance();
            const canClaim = await tb.canClaimNow();
            setCanClaim(canClaim)
        } catch (error) {
            setCanClaim(false)
        }
    }

    const [currentRate, setCurrentRate] = useState(0)
    const getRates = async () => {
        const tb = await getContractInstance()
        const tokenRate = await tb.viewRate();
        const rate = parseFloat(1 / tokenRate).toFixed(8)
        setCurrentRate(rate * 1000)
    }

    useEffect(() => {
        if (isConnected && wagmiSigner) {
            /* Set Active Menu */
            getRates()

            dispatch(menuSlice.actions.setSidebarActiveMenu('my-tokens'));
            getMyBalance();
            totalTokenUnvestedNow();
            getMyUnreleasedTokens();
            checkCanClaimNow()
            getWhenIsTheTGE()
        }
    }, [isConnected, wagmiSigner])

    return (
        <div className="nc-MyToken relative  nc-fill-container p-4">
            <Helmet>
                <title>BEC Token | Dashboard</title>
            </Helmet>
            <DashboardHeader title="My Tokens" />

            <div className="flex flex-col lg:flex-row justify-between gap-4 mt-4 h-auto lg:h-[100px]">
                <div className="flex-1 w-full py-4 px-4 flex flex-col  gap-2 justify-center bg-primary-900 rounded-lg"  >
                    <div className="text-[#050505] text-sm">Total token purchase till now</div>
                    <div className="flex flex-col">
                        <div className="text-[#050505] font-semibold text-base">{myBal} BEC</div>
                        <div className="text-[#050505] font-semibold text-xs">
                            {
                                myBal != 0 && <>{`(${(myBal * currentRate).toFixed(2)} USD)`}</>
                            }
                        </div>
                    </div>
                </div>
                <div className="flex-1 w-full py-4 px-4 flex flex-col  gap-2 justify-center bg-[#161616] rounded-lg"  >
                    <div className="text-[#FFFFFF] text-sm">Total Released Tokens till now</div>
                    <div className="flex justify-between">
                        <div className="flex flex-col">
                            <div className="text-[#FFFFFF] font-semibold text-base">{Number(claimAmt).toFixed(4)} BEC</div>
                            <div className="text-[#FFFFFF] font-semibold text-xs">
                                {
                                    Number(claimAmt) != 0 && <> {`(${(Number(claimAmt) * currentRate).toFixed(2)} USD)`}</>
                                }
                            </div>
                        </div>
                        <div>
                            <button
                                disabled={!canClaim || claimLoading}
                                onClick={claim}
                                className={`${(canClaim || !claimLoading) ? "opacity-100 cursor-pointer" : "opacity-70 cursor-help"} bg-primary-900 text-[12px] rounded-lg py-1 px-1 text-website-500 w-[5rem] 
                                flex flex-row justify-center items-center`}>
                                <span className={claimLoading ? `mr-2` : ""}>Claim</span> {claimLoading && _renderLoading()}
                            </button>
                        </div>
                    </div>
                    {/* <div className="flex justify-between">
                        <div className="text-[#FFFFFF] font-semibold text-base text-[12px]"> ( Claimed : {claimAmt})</div>
                        <div>
                            <p className="text-[12px]"> {(Number(remainingAmt)).toFixed(4)} </p>
                        </div>
                    </div> */}
                </div>
                <div className="flex-1 w-full py-4 px-4 flex flex-col  gap-2 justify-center bg-[#161616] rounded-lg" >
                    <div className="text-[#FFFFFF] text-sm">Total Unreleased Tokens till now</div>
                    <div className="flex flex-col">
                        <div className="text-[#FFFFFF] font-semibold text-base">{(Number(unReleasedTokens)).toFixed(4)} BEC</div>
                        <div className="text-[#FFFFFF] font-semibold text-xs">
                            {
                                Number(unReleasedTokens) != 0 && <>{`(${(Number(unReleasedTokens) * currentRate).toFixed(2)} USD)`}</>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-4 mt-4 bg-website-220  bg-[#161616] px-[20px] rounded-lg">
                <div className="flex flex-col text-gray-50">
                    <div className="w-full">
                        <ReleaseInstallmentCard
                            phase={1}
                            tgeDate={tgeDate}
                            dataArr={SEED_SALE}
                            title="Seed Sale"
                            description="By Weekly Release Plan"
                        />
                        <ReleaseInstallmentCard
                            phase={2}
                            title="Private Sale Round 1"
                            description="By Weekly Release Plan"
                            tgeDate={tgeDate}
                            dataArr={PRIVATE_SALE_ROUND1}
                        />
                        <ReleaseInstallmentCard
                            phase={3}
                            tgeDate={tgeDate}
                            dataArr={PRIVATE_SALE_ROUND2}
                            title="Private Sale Round 2"
                            description="By Weekly Release Plan"
                        />
                        <ReleaseInstallmentCard
                            phase={4}
                            tgeDate={tgeDate}
                            dataArr={PRE_SALE}
                            title="Pre-Sale"
                            description="By Weekly Release Plan"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PageMyToken
// const WeiToNumber = (t) => {
//     if (t === 0) return 0;
//     let tt = t / (10 ** 18)
//     return Number(Number(Math.abs(tt)).toFixed(4))
// }

const _renderLoading = (className) => {
    return (
        <svg
            className={"animate-spin -ml-1 mr-3 h-4 w-4" + " " + className}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
        >
            <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="3"
            ></circle>
            <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
        </svg>
    );
};


const calculateReleasePlansAndReturnArr = (phase, date, coinPurchased, releaseAfterEveryDay, dataArr) => {
    let lastClaimAmount = 0
    let addDay = 0
    for (let idx = 0; idx < dataArr.length; idx++) {
        let firstLaunchDate = moment(date)
        const col = dataArr[idx];
        let releasePercentageAmount = Number(col.percentage / 100) * coinPurchased
        lastClaimAmount = lastClaimAmount + releasePercentageAmount
        col.lastClaimAmount = lastClaimAmount
        col.releasePercentageAmount = releasePercentageAmount
        col.releaseDate = firstLaunchDate.add(addDay, 'days').format('DD MMMM YYYY')
        addDay = addDay + releaseAfterEveryDay
    }
    return dataArr
}

const ReleaseInstallmentCard = ({ dataArr, phase, tgeDate, title, description }) => {

    const [releasePlansArr, setReleasePlansArr] = useState([])
    const [loading, setLoading] = useState(true)
    const { getContractInstance, getConnectAddress } = useWalletContext()
    const [totalTokenPurchased, setTotalTokenPurchased] = useState(0)

    const getCoinPurchased = async (phase) => {
        setLoading(true)
        let tokensPurchased
        if (phase == 1) tokensPurchased = await getPurchasedAmount(1)
        if (phase == 2) tokensPurchased = await getPurchasedAmount(2)
        if (phase == 3) tokensPurchased = await getPurchasedAmount(3)
        if (phase == 4) tokensPurchased = await getPurchasedAmount(4)
        setLoading(false)
        setTotalTokenPurchased(tokensPurchased)
        setReleasePlansArr(calculateReleasePlansAndReturnArr(phase, tgeDate, WeiToNumber(tokensPurchased), 14, dataArr))
    }

    const getPurchasedAmount = async (id) => {
        const contractInstance = await getContractInstance();
        const connectAddress = await getConnectAddress()
        const coinPurchased = await contractInstance.tokensPurchased(id, connectAddress);
        return coinPurchased.toString()
    }

    useEffect(() => {
        if (tgeDate) getCoinPurchased(phase)
    }, [phase, tgeDate])

    if (loading && !tgeDate) {
        return <div>
            {loading && _renderLoading("w-5 h-5")}
        </div>
    }

    return <div className="py-4">
        <div className="flex flex-col justify-between">
            <div className="mb-2">
                <h2 className="text-sm font-medium">{title}</h2>
                <h2 className="text-xs text-[#949494]">{description}</h2>
            </div>
            <div className="flex items-center">
                <h2 className="text-xs text-[#949494] mr-2">Total Purchased : </h2>
                <h2 className="text-sm font-medium">{WeiToNumber(totalTokenPurchased)} BEC</h2>
            </div>

        </div>
        <div className="mt-2">
            {
                loading
                    ? _renderLoading("w-5 h-5")
                    : <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1">
                        {releasePlansArr.map(obj => <ItemCard
                            totalTokenPurchased={totalTokenPurchased}
                            key={obj.percentage + obj.releasePercentageAmount + obj.releaseDate.toString()}
                            releaseDate={obj.releaseDate}
                            percentage={obj.percentage}
                            releasePercentageAmount={obj.releasePercentageAmount}
                            lastClaimAmount={obj.lastClaimAmount}
                        />)}
                    </div>
            }

        </div>
    </div>
}

const ItemCard = ({
    percentage,
    releaseDate,
    totalTokenPurchased,
    releasePercentageAmount,
    lastClaimAmount
}) => {
    return <div className="flex flex-col bg-website-500 px-4 py-3 w-full">
        <div className="text-sm text-[#b0b0b0]"><span className="text-[#949494] text-xs">Date -</span>{releaseDate}</div>
        <div className="mb-2">
            <span className="text-xs border border-primary-900 rounded-full bg-website-220 text-white font-normal px-1">
                {percentage}%
            </span>
        </div>
        <div className="text-xs mb-1"><span className="text-[#949494]">Release -</span> {Number(Number(releasePercentageAmount).toFixed(4))} BEC</div>
        <div className="text-xs"><span className="text-[#949494]">Claimable -</span> {Number(Number(lastClaimAmount).toFixed(4))} BEC</div>
    </div>
}

