import React from "react";
import ReactDOM from "react-dom/client";

//
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "rc-slider/assets/index.css";
import { WalletProvider } from "hooks/walletContext";

//
import App from "./app";
import { Provider } from "react-redux";
import { persistor, store } from "app/store";
import { PersistGate } from "redux-persist/integration/react";


import { WagmiConfig, createClient, configureChains, mainnet } from 'wagmi'
import Buffer from 'buffer';
import { publicProvider } from 'wagmi/providers/public'
import { bsc, bscTestnet, polygonMumbai, goerli } from 'wagmi/chains'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { infuraProvider } from 'wagmi/providers/infura'
const INFURA_ID ='7e57e49eb80543e995c57144ed705082';
const { chains, provider, webSocketProvider } = configureChains(
    [mainnet,goerli],
    [
        infuraProvider({ apiKey: INFURA_ID }),
        publicProvider(),
    ],
)

if (!window.Buffer) {
    window.Buffer = Buffer.Buffer;
}

// Set up client
const client = createClient({
    autoConnect: true,
    connectors: [
        new MetaMaskConnector({
            chains,
            options: {
                appName: 'BecToken',
            }
        }),
        new WalletConnectConnector({
            chains,
            options: {
                qrcode: true,
            },
        }),
    ],
    provider,
    // webSocketProvider,
})




const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
        <WagmiConfig client={client}>
            <PersistGate loading={null} persistor={persistor}>
                <WalletProvider>
                    <App />
                </WalletProvider>
            </PersistGate>
        </WagmiConfig>
    </Provider>
);