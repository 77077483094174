import DashboardHeader from "components/Header/DashboardHeader";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import arrowRight from "images/icons/Icon feather-arrow-down-right.svg";
import CryptoToken from "components/BuyTokens/CryptoToken";
import { menuSlice } from "app/menu/menu";
import { useAccount,useSigner } from "wagmi";
import { useWalletContext } from "hooks/walletContext";
const PageBuyToken = () => {
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch()


  const [currentPhase, setCurrentPhase] = useState(0)
  const [showFiat, setShowFiat] = useState(false)
  const [showCrypto, setShowCrypto] = useState(false)
  const [bgCrypto, setBgCrypto] = useState("bg-primary-900")
  const [bgFiat, setBgFiat] = useState("bg-website-500")
  const [textCrypto, setTextCrypto] = useState("text-website-220")
  const [textFiat, setTextFiat] = useState("text-white")

  const [errorMessage, setErrorMessage] = useState(null);
  const { connector: activeConnector, isConnected, address: account, isDisconnected, status } = useAccount()
  const { data: wagmiSigner } = useSigner();


  const { getContractInstance } = useWalletContext()

  const getOnGoingPhase = async () => {
    let contract = await getContractInstance()
    let sale = await contract?.saleOngoing();
    console.log("sale ::",sale);
    setCurrentPhase(sale)
  }



  const ShowFiat = () => {
    setBgFiat("bg-primary-900")
    setBgCrypto("bg-website-500")
    setTextFiat("text-website-220")
    setTextCrypto("text-white")
    setShowFiat(true)
    setShowCrypto(false)
  }

  const ShowCrypto = () => {
    setBgCrypto("bg-primary-900")
    setBgFiat("bg-website-500")
    setTextCrypto("text-website-220")
    setTextFiat("text-white")
    setShowFiat(false)
    setShowCrypto(true)
  }


  useEffect(() => {
    setShowCrypto(true);
    if (isConnected && wagmiSigner) {
      getOnGoingPhase()
      getRates()
    }
    /* Set Active Menu */
    dispatch(menuSlice.actions.setSidebarActiveMenu('buy-tokens'));
  }, [isConnected, wagmiSigner])

  const [currentRate, setCurrentRate] = useState(0)
  const getRates = async () => {
    const tb = await getContractInstance()
    const tokenRate = await tb.viewRate();
    const rate = parseFloat(1 / tokenRate).toFixed(8)
    setCurrentRate(rate * 1000)
  }



  return (
    <div className="nc-PageHome relative overflow-hidden nc-fill-container p-4">
      <Helmet>
        <title>BEC Token | Dashboard</title>
      </Helmet>
      <DashboardHeader title="Buy Tokens" />
      <div className="mt-4 flex flex-col lg:flex lg:flex-row gap-4">
        <div className="w-full space-y-4 ">
          {/* <div className="bg-website-220 rounded-lg p-5">
            <div className="text-sm">Step 1</div>
            <div className="text-xs pt-1">
              Choose currency and calculate BEC tokens price
            </div>
            <div className=" flex flex-col md:flex md:flex-row   gap-5 pt-4">
              <div onClick={ShowCrypto} className={`${bgCrypto} ${textCrypto} w-[19.313rem] py-2 rounded-lg text-center text-base cursor-pointer`}>
                Crypto
              </div>
              <div onClick={ShowFiat} className={`${bgFiat} ${textFiat} w-[19.313rem] py-2 rounded-lg text-center text-base cursor-pointer`}>
                Fiat
              </div>
            </div>
          </div> */}
          <div className="bg-website-220 rounded-lg p-5">
            {/* <CryptoToken/> */}
            {/* <DepositEthereum /> */}
            {/* <FiatToken/> */}
            {
              showCrypto ? <CryptoToken /> : ""
            }
            {/* {
              showFiat ? <FiatToken/>:""
            } */}
          </div>
        </div>
        <div className="w-full flex flex-col  gap-4">
          <div className="flex bg-website-220 justify-center p-[10px] sm:p-5 rounded-lg">
            <div className="border border-primary-900 bg-[#E5BF0026] px-2 py-1 sm:px-10 sm:py-3 rounded-lg">
              {
                account ? <div className="text-[10px] sm:text-sm ">{account}</div>
                  :
                  <div className="flex gap-1 place-items-center">
                    <div className="text-sm">

                      Add your wallet address before buy
                    </div>
                    <div>
                      <img src={arrowRight} className="" />
                    </div>
                  </div>
              }
            </div>
          </div>
          <div className="bg-website-220 rounded-lg p-5 ">
            <div>
              <div className="text-base">
                {currentPhase == 0 && "-"}
                {currentPhase == 1 && "Seed Sale"}
                {currentPhase == 2 && "Private Sale Round 1"}
                {currentPhase == 3 && "Private Sale Round 2"}
                {currentPhase == 4 && "Pre-Sale"}

              </div>
              <div className="pt-6">
                <div>
                  <div className="text-xs text-[#949494]">USDT TOKEN PRICE</div>
                  <div className="flex">
                    <div>1 USDT =&nbsp;</div>
                    <div className="text-[#E5BF00]">{Number(Number(currentRate).toFixed(4))} BEC</div>
                  </div>
                </div>

                <div className="mt-2">
                  <div className="text-xs text-[#949494]">USDC TOKEN PRICE</div>
                  <div className="flex">
                    <div>1 USDC =&nbsp;</div>
                    <div className="text-[#E5BF00]">{Number(Number(currentRate).toFixed(4))} BEC</div>
                  </div>
                </div>

                <div className="mt-2">
                  <div className="text-xs text-[#949494]">BUSD TOKEN PRICE</div>
                  <div className="flex">
                    <div>1 BUSD =&nbsp;</div>
                    <div className="text-[#E5BF00]">{Number(Number(currentRate).toFixed(4))} BEC</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageBuyToken;
