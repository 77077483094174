import DashboardHeader from "components/Header/DashboardHeader";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import DollarIcon from 'images/icons/us-dollar.png'
import { isNumber, WeiToNumber } from "utils/helpers";
import { menuSlice } from "app/menu/menu";
import iconone from "../../images/icons/1200px-Bitcoin.svg-1.png"
import icontwo from "../../images/icons/5F33E3F751873296-1.png"
import TokenStageProgressDashboard from "components/TokenStageProgress/TokenStageProgressDashboard";
import { useWalletContext } from "hooks/walletContext";
import { useAccount, useSigner, useProvider, useBalance, useConnect, useDisconnect, useEnsAvatar, useEnsName, useNetwork, useSwitchNetwork } from 'wagmi'



const Page = () => {
    const NUMBER_REGEX = /^-?\d*\.?\d*$/

    const dispatch = useDispatch()
    const { user } = useSelector((state => state))
    const [eth, setEth] = useState('')
    const [rangeVal, setRangeVal] = useState(4)

    const { getContractInstance, getConnectAddress } = useWalletContext();
    const { address, connector, isConnected } = useAccount();
    const { data: wagmiSigner } = useSigner();

    const Data = [
        {
            "date": "2022-08-24 10:20 PM",
            "amount": "50.00 ETH",
            "token": "18,750",
            "status": "Fern",
            "icon": icontwo

        },

        {
            "date": "2022-08-24 10:20 PM",
            "amount": "50.00 ETH",
            "token": "18,750",
            "status": "Fern",
            "icon": iconone

        },

        {
            "date": "2022-08-24 10:20 PM",
            "amount": "50.00 ETH",
            "token": "18,750",
            "status": "Fern",
            "icon": icontwo

        },
        {
            "date": "2022-08-24 10:20 PM",
            "amount": "50.00 ETH",
            "token": "18,750",
            "status": "Fern",
            "icon": icontwo

        },


    ]



    const onChangeEth = (e) => {
        let eth = e.target.value.toString()
        if (isNumber(eth)) setEth(eth)
    }

    const updateRange = (val) => { setRangeVal(val) }

    const [rate, setRate] = React.useState(0)
    const getOngoingSale = async () => {
        try {
            if (!isConnected && !wagmiSigner) return
            console.log("wagmiSigner ::", wagmiSigner);
            const tb = await getContractInstance();
            let rate = await tb.saleOngoing()
            console.log("rate.toString()", rate.toString());
            setRate(+rate.toString())
        } catch (error) {
            console.log("getOngoingSale :: error :", error);
        }
    }

    const convertOtherCoinToBEC = (x) => {
        if (!x) {
            x = 1
        }
        x = x * (rate)
        return x
    };

    const [contributionAmount, setContributionAmount] = useState(0)
    const getMyContributionAmount = async () => {
        try {
            const contractInstance = await getContractInstance();
            const connectAddress = await getConnectAddress()
            const returnAmountPaid = await contractInstance.showPurchasedAmount(connectAddress);
            setContributionAmount(returnAmountPaid.toString())
        } catch (error) {
            console.log("getMyContributionAmount ::",error.message);
        }
    }

    const [myBalance, setmyBalance] = useState(0)
    const getMyBalance =async () => {
        let values =[];
        const tb = await getContractInstance();
        const tp = await tb.tokensPurchased(1, address);
        console.log("tokenPurchased :: 1", tp.toString());
        values.push(tp.toString())

        const tp2 = await tb.tokensPurchased(2, address);
        console.log("tokenPurchased ::2", tp2.toString());
        values.push(tp2.toString())

        const tp3 = await tb.tokensPurchased(3, address);
        console.log("tokenPurchased ::3", tp3.toString());
        values.push(tp3.toString())

        const tp4 = await tb.tokensPurchased(4, address);
        console.log("tokenPurchased ::4", tp4.toString());
        values.push(tp4.toString())
        // Promise.all([promiseStage1Await]).then((values) => {
            const bal = values.reduce(
                (accumulator, currentValue) => accumulator + Number(currentValue),
                0
            );
            setmyBalance(bal)
            console.log("values", bal);
        // });
    }

    const [currentRate, setCurrentRate] = useState(0)
    const getRates = async () => {
        const tb = await getContractInstance();
        const tokenRate = await tb.viewRate();
        const rate = parseFloat(1 / tokenRate).toFixed(8)
        const rate1 = parseFloat((+tokenRate.toString())/1000);
        setCurrentRate(rate*1000);
        setRate(rate1);
    }


    useEffect(() => {
        /* Set Active Menu */
        if (isConnected && wagmiSigner) {
            // getOngoingSale()
            getRates()
            getMyBalance()
            getMyContributionAmount()
        }
        dispatch(menuSlice.actions.setSidebarActiveMenu('dashboard'));
    }, [isConnected, wagmiSigner])
    return (

        <div className="nc-PageHome relative overflow-hidden nc-fill-container p-4">
            <Helmet>
                <title>BEC Token | Dashboard</title>
            </Helmet>
            <DashboardHeader title="Dashboard" />

            <div className="mt-4 flex flex-col gap-2 sm:flex sm:flex-row">
                <div className=" divWidth320 divWidth bg-primary-900 rounded-lg px-5 py-4 flex flex-col sm:flex sm:flex-row items-center justify-between sm:w-[30%]">
                    <div className="text-website-500 text-sm font-medium">Tokens Balance</div>
                    <div className="flex flex-col">
                        <div className="text-website-500 text-xl font-medium">{Number(Number(WeiToNumber(myBalance)).toFixed(4))} BEC</div>
                        <div className="text-website-500 text-xs font-medium">{`(${Number(Number(WeiToNumber(myBalance)) * currentRate).toFixed(2)} USD)`}</div>
                    </div>
                </div>
                <div className="flex-1 divWidth320 divWidth rounded-lg px-5 py-4 bg-website-220 flex flex-col sm:flex sm:flex-row  items-center sm:ml-2">
                    <div className="text-white text-sm font-medium mr-4">Your Contribution :</div>
                    <div className="text-white text-sm font-medium">{contributionAmount} USD</div>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row mt-4 gap-5">
                <div className=" divWidth320 divWidth bg-website-220 p-5 rounded-md  lg:w-[40.5%]">
                    <div className="text-base">Token Calculation</div>
                    <div className="text-xs text-[#949494] mt-1" style={{ marginBottom: "50px" }}>Enter amount to calculate token</div>

                    <div className="">
                        <div className=" flex relative items-center">
                            <input
                                autoFocus
                                value={eth}
                                onChange={onChangeEth}
                                type={'tel'}
                                className="absolute w-full rounded bg-website-500 px-2 py-4 border-none active:border-none text-sm font-normal p-0"
                                placeholder="Enter Amount In USD"
                                style={{ boxShadow: 'none' }}
                            />
                            <div className="flex absolute right-2 left-auto items-center"><img className="h-[18px] w-[18px] mr-1" src={DollarIcon} /> <span>USD</span></div>
                        </div>
                    </div>
                    <div className='text-sm mt-8'>{eth || 1} USD = <span className='text-primary-900'>{convertOtherCoinToBEC(eth).toFixed(4)} BEC</span></div>
                    <div className="mt-6 flex justify-end">
                        <button className=" bg-primary-900 py-2 px-7 text-website-500 font-medum text-sm rounded">Convert</button>
                    </div>


                    {/* <div className="bg-website-500 px-2 py-4 items-center flex justify-between mt-6">
                        <input
                            autoFocus
                            value={eth}
                            onChange={onChangeEth}
                            type={'tel'}
                            className="bg-transparent border-none active:border-none text-sm font-normal p-0"
                            placeholder="Enter Amount In USD"
                            style={{ boxShadow: 'none' }}
                        />
                        <div className="flex items-center"><img className="h-[18px] w-[18px] mr-1" src={ETH_ICON} /> <span>USD</span></div>
                    </div>
                    <div className='text-sm mt-1'>{eth || 1} USD = <span className='text-primary-900'>{convertOtherCoinToBEC(eth)} BEC</span></div> */}
                </div>

                <div className="divWidth320 divWidth bg-website-220 p-5 rounded-md lg:w-[64.7%] ">
                    <div className="text-base">Token Sales Progress</div>
                    <div className="text-xs text-[#949494] mt-1">You can see here all sales graph about our Token</div>

                    <div className="px-2 py-4 justify-between mt-6">
                        <TokenStageProgressDashboard />
                    </div>
                </div>
            </div>
            <div className="hidden p-5 bg-[#161616] mt-6 rounded-md" >
                <div className="flex flex-row justify-between " >
                    <div> <h1 className="text-base text-white">My Transaction</h1></div>
                    <div className="text-primary-900 text-[12px] cursor-pointer">View All</div>

                </div>

                <h1 className="text-xs text-[#949494]">You can see all your transaction here</h1>

                <div class="flex flex-col">
                    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                            <div class="overflow-x-auto">
                                <table class="min-w-full">
                                    <thead class="border-b">
                                        <tr className='h-[54px] 1px solid #303030' >
                                            <th className='text-left text-[12px] md:w-[35%]'>Date</th>
                                            <th className='text-left text-[12px] md:w-[26%]'>Amount</th>
                                            <th className='text-left text-[12px]  md:w-[26%] '>BEC Token Receive</th>
                                            <th className='text-left text-[12px] '>Status</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Data.map((data, index) => {
                                            return <tr key={index} className='h-[54px]' style={{ borderTop: '1px solid #303030', borderBottom: '1px solid #303030' }}>
                                                <td className='text-[12px] text-start md:w-[35%]'>{data.date}</td>
                                                <td className='text-[12px] text-start md:w-[26%] '><span className="w-[18px] h-[18px] mr-1" ><img className="inline mt-[-2px]" src={data.icon} /></span>{data.amount}</td>
                                                <td className='text-[12px] text-start   md:w-[26%]' >{data.token}</td>
                                                <td className='text-[12px] text-start rounded-2xl '><button style={{ border: '1px solid #1EDF65', background: " #1EDF6529 0% 0% no-repeat padding-box" }} className="rounded-2xl bg-[] h-[22px] w-[77px] text-[12px] text-[#1EDF65] text-center">Success</button></td>

                                            </tr>;
                                        })}







                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page;
