import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ConversionD from "./conversionComp";
import { useSelector } from "react-redux";
import PageConnectWallet from "containers/PageConnectWallet/PageConnectWallet";
import ButtonSecondary from "shared/Button/ButtonSecondary";

const BecTableContainer = () => {
    const { user } = useSelector((state => state))
    const [isShow, setIsShow] = useState(false)
    const toggleModal = () => {
        let t = !isShow;
        setIsShow(!isShow)
    }

    return (
        <>
            <div className="container mt-8 relative space-y-[150px] mb-[150px]">
            <PageConnectWallet isShow={isShow} toggleModal={toggleModal} />

                <div className="relative flex flex-col md:flex-row md:justify-between">
                    <div className="w-[700px] h-[500px] bg-[#ffffff2b] rounded-full absolute blur-[150px] " />
                    <div className="flex flex-col" style={{ alignSelf: 'center' }}>
                        <div className="word-outer mb-1">
                            <h1 className="word-outline-size word-outline">BEC</h1>
                        </div>
                        <div className="word-outer mb-1">
                            <h1 className="word-outline-size word-outline-fill">Token</h1>
                        </div>
                        <div className="word-outer mb-1">
                            <h1 className="word-outline-size word-outline">Utility</h1>
                        </div>
                        <div className="word-outer mb-1">
                            <h1 className="word-outline-size word-outline">Ecosystem</h1>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="max-w-[512px]">
                            <ConversionD />
                        </div>
                        <div className=" flex justify-center place-items-center">
                            <div className="flex justify-center">
                                <h1 className="text-[#5e5e5e] text-[3rem] font-medium text-center mr-4" style={{ lineHeight: "60px" }}>Token sale</h1>
                            </div>
                            <div className=" flex justify-center">
                                {
                                    user.id ? <Link to={"/dashboard/buy-tokens"}>
                                        <ButtonSecondary className="text-[#5e5e5e] h-14 sm:h-11 bg-primary-900 py-2.5 px-11 font-semibold rounded-3xl lg:text-xs xl:text-sm "><span className="text-[#5e5e5e]">Buy Token</span></ButtonSecondary>
                                    </Link>
                                        : window.ethereum?.isMetaMask && <ButtonSecondary onClick={() => toggleModal()} className=" h-14 sm:h-11 bg-primary-900 py-2.5 px-11  font-semibold rounded-3xl"><span className="text-[#5e5e5e]">Buy Token</span></ButtonSecondary>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BecTableContainer;
